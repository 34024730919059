<template>
  <div @click="closeModal" class="modal-close-widget">
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0744 10.9109C10.0058 10.9795 9.92696 11.0172 9.83782 11.024C9.74868 11.0172 9.66982 10.9795 9.60125 10.9109L7.46179 8.77144L5.35319 10.88C5.28462 10.9486 5.20576 10.9863 5.11661 10.9932C5.02747 10.9863 4.94861 10.9486 4.88004 10.88L4.55089 10.5509C4.48232 10.4823 4.44803 10.4069 4.44803 10.3246C4.44803 10.2286 4.48232 10.1463 4.55089 10.0777L6.65949 7.96914L4.58175 5.8914C4.51318 5.82283 4.47889 5.7474 4.47889 5.66511C4.47889 5.56911 4.51318 5.48682 4.58175 5.41825L4.94175 5.05825C5.01033 4.98967 5.08918 4.95882 5.17833 4.96567C5.26747 4.95882 5.34633 4.98967 5.4149 5.05825L7.49265 7.13599L9.61153 5.0171C9.6801 4.94853 9.75896 4.91767 9.84811 4.92453C9.93725 4.91767 10.0161 4.94853 10.0847 5.0171L10.4138 5.34625C10.4824 5.41482 10.5167 5.49711 10.5167 5.59311C10.5167 5.6754 10.4824 5.75083 10.4138 5.8194L8.29494 7.93829L10.4344 10.0777C10.503 10.1463 10.5373 10.2286 10.5373 10.3246C10.5373 10.4069 10.503 10.4823 10.4344 10.5509L10.0744 10.9109Z"
        fill="#B8B8CB"
        stroke="#B8B8CB"
        stroke-width="0.5"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "ModalCloseWidget",
  mixins: [],
  props: {
    modalName: {
      type: String
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$root.$emit(`closeModal_${this.modalName}`);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";

.modal-close-widget {
  width: max-content;
  cursor: pointer;

  svg {
    path {
      transition: all 0.2s ease;
    }

    &:hover {
      path {
        stroke: $color-button-hover;
      }
    }

    &:active {
      path {
        stroke: $color-button-click;
      }
    }
  }
}
</style>
